<template>
	<Layout>
		<v-container>
			<v-row align="center">
				<v-col class="mx-auto" cols="12" sm="8">
					<v-card dark >
						<v-card-title>
							<v-toolbar-title>EDIT MY PROFLE</v-toolbar-title>
						</v-card-title>
						<v-card-text>
							<v-container class="pt-5">
								<v-form ref="form" v-model="valid">
									<v-row>
										<v-col cols="12" sm="6">
											<v-text-field
													prepend-icon="mdi-account-reactivate-outline"
													label="Name"
													v-model="editedItem.name"
													:rules="[rules.required]"
											/>
											<v-text-field
													prepend-icon="mdi-account-reactivate-outline"
													label="Last name"
													v-model="editedItem.lastName"
													:rules="[rules.required]"
											/>
											<v-text-field
													prepend-icon="mdi-email-outline"
													label="E-mail"
													v-model="editedItem.email"
													:rules="[rules.required, rules.email]"
											/>
											<v-text-field
													prepend-icon="mdi-office-building-outlinee"
													label="Company"
													v-model="editedItem.company"
											/>
											<v-text-field
													prepend-icon="mdi-cards-variant"
													label="Departament"
													v-model="editedItem.department"
											/>
											<v-text-field
													type="tel"
													prepend-icon="mdi-phone"
													label="Phone"
													v-model="editedItem.phone"
													:rules="[rules.required]"
											/>
										</v-col>
										<v-col cols="12" sm="6">

											<v-textarea
													dense
													outlined
													prepend-icon="mdi-map-marker-radius-outline"
													v-model="editedItem.address"
													label="Street address"
													:value="editedItem.address"
													rows="2"
													:rules="[rules.required]"
											/>
											<v-text-field
													prepend-icon="mdi-home-city-outline"
													label="City"
													v-model="editedItem.city"
													:rules="[rules.required]"
											/>
											<v-text-field
													prepend-icon="mdi-city-variant-outline"
													label="State"
													v-model="editedItem.state"
													:rules="[rules.required]"
											/>
											<v-text-field
													prepend-icon="mdi-flag-outline"
													label="Country"
													v-model="editedItem.country"
													:rules="[rules.required]"
											/>
											<div class="my-5">
												<v-divider />
											</div>
											<v-text-field
													type="password"
													color="primary"
													v-model="password"
													label="Password (Leave blank if you don't want change it)"
													dense
													outlined />
										</v-col>
									</v-row>
								</v-form>
							</v-container>
						</v-card-text>
						<v-card-actions class="primary">
								<v-btn text @click="cancel"> <v-icon color="white">mdi-close</v-icon> Cancel</v-btn>
								<v-spacer />
								<v-btn :loading="saving" @click.stop="validate()" > <v-icon class="mr-3" color="white">mdi-check-circle</v-icon> SAVE</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</Layout>
</template>

<script>
	import {mapState, mapActions} from 'vuex';
	export default {
		name: 'Profile',
		data: ()=>({
			password: '',
      passwordLabel: '',
      saving: false,
      editedIndex: -1,
      valid: true,
      editedItem: {
				_id: '',
				role: '',
        name: '',
        lastName: '',
        company: '',
        department: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        password: '',
      },
      rules: {
        required: value => !!value || 'Item is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Must be valid e-mail'
        },
      },
		}),
		mounted(){
			// console.log( this.$store.state.authModule.user )
			// console.log( this.id )
			// console.log( this.role )
			this.getUser(this.id)
		},
		methods: {
			...mapActions('userModule',['getUser','updateUser']),
			cancel() {
      	this.$router.push({name: 'Dashboard'})
			},
			validate () {
				if (this.$refs.form.validate()) {
					this.saveUser()
				}
			},
			saveUser(){
				this.editedItem.email = this.editedItem.email.trim().toLowerCase()
				this.editedItem.password = this.password.trim()
				this.editedItem._id = this.id
				this.editedItem.role = this.role

				this.saving = true;

				if (this.user._id != null) {
					this.updateUser(this.editedItem)
				}
				this.password = ''
				this.$refs.form.resetValidation()
				this.saving = false;
			},

		},
		computed: {
			...mapState(['loading', 'dialog']),
    	...mapState('userModule',['users','user']),
			id(){
				return this.$store.state.authModule.user._id
			},
			role(){
				return this.$store.state.authModule.user.role
			}
		},
		watch: {
			'user': function() {
				if (this.user != null){ this.editedItem = this.user; }
			}
  }
	}
</script>

<style lang="scss" scoped>

</style>